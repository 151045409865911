* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Outfit !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar-track {
  /* background-color: #f0f0f0; */
  background-color: transparent;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #fd5d2954;
  border-radius: 12px;
  border: 3px solid #f0f0f0;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.hideScrollBar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* input[type="checkbox"]:checked {
   background-color: #002B4E !important;
   color: white;
 } */

input[type="search"]::placeholder {
  color: #fd5d29;
}

input[type="search"] {
  outline: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  transition: all 0.1s linear;
}

input[type="search"]:focus::-webkit-search-cancel-button:hover {
  cursor: pointer;
  scale: 1.5;
}

input[type="search"]:hover {
  background-color: #fd5d2915;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.flexStartCol {
  display: "flex";
  align-items: "start";
}